html {
  height: 100%;
  /* background: rgb(0,71,96);
  background: linear-gradient(160deg, rgba(0,71,96,1) 32%, rgba(3,165,166,1) 92%); */
  background-color: #075e7e;
}

body {
  height: 100%;
  background-color: #075e7e;
}

.my-photo {
  width: 300px;
  max-height: 250px;
  object-fit: cover;
}

.my-word {
  width: 280px;
  max-height: 100px;
  object-fit: cover;
}

.static-position {
  position: absolute;
  right: 4%;
  top: 10%;
}

.my-footer {
  position:sticky;
  padding-top: 2%;
  padding-bottom: 10px;
}

.my-footer2 {
  padding-top: 2%;
  padding-bottom: 10px;
}

.my-h2 {
  padding-bottom: 1%;
}

p,
h1,
h2,
nav,
div {
  font-family: 'Yusei Magic', sans-serif;
}

.App {

height: 100%;
}

#root {

height: 100%;
}
#real-container {
  height: 100%;
}

form {
  width: 60%;
}

@media (max-width: 767px) {
  form {
    width: 100%;
  }
}

#wordpress {
    :hover {
        opacity: 0.8;
    }
}

#twitter {
    :hover {
        opacity: 0.8;
    }
}

#instagram {
    :hover {
        opacity: 0.8;
    }
}

#wordpress_elem {
    margin-top: -5px;
    width: 25px;
    height: 25px;
}

#twitter_elem {
    margin-top: -5px;
    width: 18px;
    height: 18px;
}

#instagram_elem {
    margin-top: -5px;
    margin-left: 5px;
    width: 18px;
    height: 18px;
}
